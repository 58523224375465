var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('form',{ref:"formContainer",staticClass:"form-horizontal vld-parent",on:{"submit":function($event){$event.preventDefault();return _vm.createLog.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Title*")]),_c('b-form-radio-group',{staticClass:"mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.department.$error,
                        },attrs:{"options":_vm.registrationOptions,"value-field":"item","text-field":"name","disabled-field":"notEnabled"},model:{value:(_vm.log.visitor_type),callback:function ($$v) {_vm.$set(_vm.log, "visitor_type", $$v)},expression:"log.visitor_type"}}),(_vm.submitted && _vm.$v.log.visitor_type.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.visitor_type.required)?_c('span',[_vm._v("Title is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Communicated Via*")]),_c('b-form-radio-group',{staticClass:"mb-3",class:{
                            'is-invalid': _vm.submitted && _vm.$v.log.via.$error,
                        },attrs:{"options":_vm.communicationOptions,"value-field":"item","text-field":"name","disabled-field":"notEnabled"},model:{value:(_vm.log.via),callback:function ($$v) {_vm.$set(_vm.log, "via", $$v)},expression:"log.via"}}),(_vm.submitted && _vm.$v.log.via.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.via.required)?_c('span',[_vm._v("Communication filed is required")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Department*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.department),expression:"log.department"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.department.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.department)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "department", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.department.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.department.required)?_c('span',[_vm._v("Department required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Subject*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.subject),expression:"log.subject"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.subject.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "subject", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.subject.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.subject.required)?_c('span',[_vm._v("Subject required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Concerned Person*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.concerned_person),expression:"log.concerned_person"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.concerned_person.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.concerned_person)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "concerned_person", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.concerned_person.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.concerned_person.required)?_c('span',[_vm._v("Concerned person required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Attended By*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.attended_by),expression:"log.attended_by"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.attended_by.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.attended_by)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "attended_by", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.attended_by.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.attended_by.required)?_c('span',[_vm._v("Attended by is required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Title*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.name_title.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.titles},model:{value:(_vm.log.name_title),callback:function ($$v) {_vm.$set(_vm.log, "name_title", $$v)},expression:"log.name_title"}}),(_vm.submitted && _vm.$v.log.name_title.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.name_title.required)?_c('span',[_vm._v("Title is required")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Company Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.company),expression:"log.company"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.company.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "company", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.company.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.company.required)?_c('span',[_vm._v("Company is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Customer Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.customer_name),expression:"log.customer_name"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.customer_name.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.customer_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "customer_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.customer_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.customer_name.required)?_c('span',[_vm._v("Customer name is required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.unit_no),expression:"log.unit_no"},{name:"mask",rawName:"v-mask",value:('WR##-##'),expression:"'WR##-##'"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.unit_no.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.unit_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "unit_no", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.unit_no.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.unit_no.required)?_c('span',[_vm._v("Unit number is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Phone Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.phone_no),expression:"log.phone_no"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.phone_no.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.phone_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "phone_no", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.phone_no.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.phone_no.required)?_c('span',[_vm._v("Phone number is required")]):_vm._e(),(!_vm.$v.log.phone_no.numeric)?_c('span',[_vm._v("Only numeric values are allowed")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Mobile Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.mobile_no),expression:"log.mobile_no"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.mobile_no.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.mobile_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "mobile_no", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.mobile_no.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.mobile_no.required)?_c('span',[_vm._v("Mobile number is required")]):_vm._e(),(!_vm.$v.log.mobile_no.numeric)?_c('span',[_vm._v("Only numeric values are allowed")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Email*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.email),expression:"log.email"}],staticClass:"form-control mb-3",class:{
                            'is-invalid': _vm.submitted && _vm.$v.log.email.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.email.required)?_c('span',[_vm._v("Email is required")]):_vm._e(),(!_vm.$v.log.email.email)?_c('span',[_vm._v("This value should be a valid email.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Mailing address*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.mailing_address),expression:"log.mailing_address"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.mailing_address.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.mailing_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "mailing_address", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.mailing_address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.mailing_address.required)?_c('span',[_vm._v("Mailing address is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Physical address*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.log.physical_address),expression:"log.physical_address"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.log.physical_address.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.log.physical_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.log, "physical_address", $event.target.value)}}}),(_vm.submitted && _vm.$v.log.physical_address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.physical_address.required)?_c('span',[_vm._v("Physical address is required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Your comment *")]),_c('b-form-textarea',{staticClass:"mt-3 mb-3",class:{
                        'is-invalid':
                            _vm.submitted && _vm.$v.log.customer_comments.$error,
                    },attrs:{"id":"textarea-small","size":"sm"},model:{value:(_vm.log.customer_comments),callback:function ($$v) {_vm.$set(_vm.log, "customer_comments", $$v)},expression:"log.customer_comments"}}),(_vm.submitted && _vm.$v.log.customer_comments.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.log.customer_comments.required)?_c('span',[_vm._v("Customer comment is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Attachment")]),_c('b-form-file',{staticClass:"mb-3",attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf,image/jpg"},on:{"change":_vm.handleAttachment}})],1)]),_c('submitButton')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }