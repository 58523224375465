<template>
    <Layout>
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="createLog"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Title*</label>
                        <b-form-radio-group
                            v-model="log.visitor_type"
                            :options="registrationOptions"
                            class="mb-3"
                            value-field="item"
                            text-field="name"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.department.$error,
                            }"
                            disabled-field="notEnabled"
                        ></b-form-radio-group>
                        <div
                            v-if="submitted && $v.log.visitor_type.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.visitor_type.required"
                                >Title is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Communicated Via*</label>
                        <b-form-radio-group
                            v-model="log.via"
                            :options="communicationOptions"
                            class="mb-3"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                            :class="{
                                'is-invalid': submitted && $v.log.via.$error,
                            }"
                        ></b-form-radio-group>
                        <div
                            v-if="submitted && $v.log.via.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.via.required"
                                >Communication filed is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Department*</label>
                        <input
                            v-model="log.department"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.department.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.department.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.department.required"
                                >Department required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Subject*</label>
                        <input
                            v-model="log.subject"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.subject.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.subject.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.subject.required"
                                >Subject required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Concerned Person*</label>
                        <input
                            v-model="log.concerned_person"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.concerned_person.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.concerned_person.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.concerned_person.required"
                                >Concerned person required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Attended By*</label>
                        <input
                            v-model="log.attended_by"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.attended_by.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.attended_by.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.attended_by.required"
                                >Attended by is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="ownerfname">Title*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="log.name_title"
                            :options="titles"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.name_title.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.log.name_title.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.name_title.required"
                                >Title is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Company Name*</label>
                        <input
                            v-model="log.company"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.company.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.company.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.company.required"
                                >Company is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Customer Name*</label>
                        <input
                            v-model="log.customer_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.customer_name.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.customer_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.customer_name.required"
                                >Customer name is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit Number*</label>
                        <input
                            v-model="log.unit_no"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            v-mask="'WR##-##'"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.unit_no.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.unit_no.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.unit_no.required"
                                >Unit number is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Phone Number*</label>
                        <input
                            v-model="log.phone_no"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.phone_no.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.phone_no.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.phone_no.required"
                                >Phone number is required</span
                            >
                            <span v-if="!$v.log.phone_no.numeric"
                                >Only numeric values are allowed</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Mobile Number*</label>
                        <input
                            v-model="log.mobile_no"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.mobile_no.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.mobile_no.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.mobile_no.required"
                                >Mobile number is required</span
                            >
                            <span v-if="!$v.log.mobile_no.numeric"
                                >Only numeric values are allowed</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Email*</label>
                        <input
                            v-model="log.email"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid': submitted && $v.log.email.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.email.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.email.required"
                                >Email is required</span
                            >
                            <span v-if="!$v.log.email.email"
                                >This value should be a valid email.</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Mailing address*</label>
                        <input
                            v-model="log.mailing_address"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.mailing_address.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.mailing_address.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.mailing_address.required"
                                >Mailing address is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Physical address*</label>
                        <input
                            v-model="log.physical_address"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.log.physical_address.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.log.physical_address.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.log.physical_address.required"
                                >Physical address is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <label for="ownerfname"> Your comment *</label>
                    <b-form-textarea
                        v-model="log.customer_comments"
                        id="textarea-small"
                        size="sm"
                        class="mt-3 mb-3"
                        :class="{
                            'is-invalid':
                                submitted && $v.log.customer_comments.$error,
                        }"
                    ></b-form-textarea>
                    <div
                        v-if="submitted && $v.log.customer_comments.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.log.customer_comments.required"
                            >Customer comment is required</span
                        >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <label for="ownerfname">Attachment</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleAttachment"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf,image/jpg"
                    ></b-form-file>
                    <!-- :class="{ 'is-invalid': submitted && $v.log.document.$error,
                    }" -->
                    <!-- <div
                        v-if="submitted && $v.log.document.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.log.document.required"
                            >Attachment file is required</span
                        >
                    </div> -->
                </div>
            </div>

            <submitButton />
        </form>
    </Layout>
</template>
<script>
/* eslint-disable no-unused-vars */

import Layout from "../../layouts/main.vue";
import submitButton from "../../../components/submitButton.vue";
import alertMixin from "../../../mixins/alertMixin";
import { BASE_URL } from "../../common";
import { required, numeric, email } from "vuelidate/lib/validators";
import axios from "axios";
export default {
    mixins: [alertMixin],
    components: {
        Layout,
        submitButton,
    },
    validations: {
        // document: { required },
        log: {
            visitor_type: { required },
            company: { required },
            customer_name: { required },
            name_title: { required },
            subject: { required },
            attended_by: { required },
            concerned_person: { required },
            unit_no: { required },
            phone_no: { required, numeric },
            mobile_no: { required, numeric },
            email: { required, email },
            mailing_address: { required },
            physical_address: { required },
            customer_comments: { required },
            via: { required },
            department: { required },
        },
    },
    methods: {
        createLog() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                console.log(this.log);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}visitors?token=${localStorage.cs_user_token}`,
                            this.log
                        )
                        .then((res) => {
                            if (res.data.status_code === 200) {
                                this.successPopUp(
                                    "Visitor details saved successfully"
                                );
                                this.resetFields();
                            }
                            loader.hide();
                        })
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
        handleAttachment(e) {
            let reader = new FileReader();
            this.log.document_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.log.document = e.target.result.split(",")[1];
                console.log(this.log.document);
            };
            reader.readAsDataURL(e.target.files[0]);
        },
    },
    data() {
        return {
            registrationOptions: [
                { item: "1", name: "Owner" },
                { item: "2", name: "Tenant" },
                { item: "3", name: "Other" },
            ],
            communicationOptions: [
                { item: "Walk In", name: "Walk In" },
                { item: "Phone Call", name: "Phone Call" },
                { item: "Mail", name: " Mail" },
                { item: "Message", name: " Message" },
            ],
            titles: [
                { value: "mr", text: "Mr." },
                { value: "ms", text: "Ms." },
                { value: "company", text: "Company" },
            ],
            submitted: false,
            fullPage: false,
            log: {
                document: "",
                document_filename: "",
                visitor_type: "",
                company: "",
                customer_name: "",
                name_title: "",
                subject: "",
                attended_by: "",
                concerned_person: "",
                unit_no: "",
                phone_no: "",
                mobile_no: "",
                email: "",
                mailing_address: "",
                physical_address: "",
                customer_comments: "",
                via: "",
                department: "",
            },
        };
    },
};
</script>
<style scoped></style>
