import { render, staticRenderFns } from "./newVisitorLog.vue?vue&type=template&id=8fbaac4e&scoped=true&"
import script from "./newVisitorLog.vue?vue&type=script&lang=js&"
export * from "./newVisitorLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fbaac4e",
  null
  
)

export default component.exports